@import './../../styles/colors';
@import './../../styles/variables';

.mainheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  height: $header-height;
  border-radius: 0 !important;

  &_logo {
    width: 180px;
    height: auto;
    margin-right: 15px;
  }

  &_title {
    display: flex;
    align-items: center;
    &_logo {
      height: 40px;
      img {
        height: 100%;
        width: auto;
      }
    }
    &_text {
      font-size: 18px;
    }
  }

  &-divider {
    width: 1px;
    border-right: 2px solid $color-medium;
    height: 40% !important;
  }

  &_session {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60%;
    border-left: 1px solid $color-medium;
    &_usericon {
      margin: 0 10px;
      color: white;
      font-size: 40px;
    }

    &_profile {
      &_user {
        font-size: 12px;
        color: white;
      }
    }

    &_logout {
      height: 100% !important;
      margin-left: 10px;
      padding: 0 10px;
      border-left: 1px solid $color-medium;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      &_icon {
        color: white;
        background-color: transparent;
      }
    }
  }
}
