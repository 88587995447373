@import '../../../styles/colors';

.dealmodal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  #MUI_description {
    grid-column: 1 / end-line;
  }
}
