.evaluations {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filters {
      display: flex;
      align-items: flex-end;
      #MUI_group {
        width: 200px;
        margin-right: 10px;
      }
      #MUI_period {
        width: 260px;
        margin-right: 10px;
      }
      #filterby {
        width: 240px;
      }
    }
    &_actions {
      display: flex;
    }
  }
  &_records {
    display: grid;
    grid-template-columns: repeat(7, 30px);
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    font-size: 10px;
    &_item {
      min-width: max-content;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
