.usermodal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  &_active {
    grid-column: 1 / 3;
    justify-self: end;
  }
  #MUI_role_id {
    font-size: 12px !important;
    line-height: 12px !important;
  }
}
