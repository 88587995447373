.dashboard {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filters {
      display: flex;
      align-items: flex-end;
      #MUI_group {
        width: 200px;
        margin-right: 10px;
      }
      #MUI_period {
        width: 260px;
        margin-right: 10px;
      }
      #filterby {
        width: 240px;
      }
    }
    &_actions {
      display: flex;
    }
  }
  &_content {
    padding: 10px;
    &_graphs {
      display: grid;
      grid-template-columns: 500px 1fr;
      grid-column-gap: 10px;
      padding-top: 40px;
    }
  }
}
