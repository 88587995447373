.users {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filterby {
    }
  }
  &_active {
    cursor: pointer;
  }
}
.g-page-header {
  min-height: 0 !important;
}
