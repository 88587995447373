@import '../../../styles/colors';

.recordModal {
  display: grid;
  flex-direction: row;

  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  &_record {
    &_label {
      font-family: OpenSans-Bold;
    }
    &_item {
      cursor: pointer;
      text-align: right;
      user-select: none;
      background-color: $color-light;
      padding: 2px 5px;
      &.selected {
        border: 1px solid $color-cancel;
        font-family: OpenSans-Bold;
        font-size: 16px;
        border-radius: 5px;
        background-color: $color-check;
        padding: 0 5px;
        // background-color: $color-secondary;
      }
    }
  }
}
