.groupStaffs {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filters {
      display: flex;
      align-items: flex-end;
      #MUI_company {
        width: 200px;
        margin-right: 10px;
      }
      #filterby {
        width: 240px;
      }
    }
  }
}
